/*========== Start Compact Menu ==========*/

$('.btn-menu, nav a').on('click', function () {
	var tm = TweenMax;

	$('.btn-menu').toggleClass('open');
	if ($('.btn-menu').hasClass('open')) {
		$('nav').addClass('visible');
		document.body.classList.add("no-scroll");

		tm.set('nav', { opacity: 0 });
		$('nav').each(function () {
			tm.to('nav', 0.5, { opacity: 1, delay: 0.5, ease: Power2.easeOut });
		});
		tm.set('main', { opacity: 1 });
		$('main').each(function () {
			tm.to('main', 0.5, { opacity: 0, delay: 0.5, ease: Power2.easeOut });
		});
	} else {
		document.body.classList.remove("no-scroll");
		$('nav').each(function () {
			tm.to('nav', 0.5, { opacity: 0, delay: 0.1, ease: Power2.easeOut });
		});
		$('main').each(function () {
			tm.to('main', 0.5, { opacity: 1, delay: 0.5, ease: Power2.easeOut });
		});
		setTimeout(function () {
			$('nav').removeClass('visible');
		}, 1000);
	}
});

/*========== End Compact Menu ==========*/