	$(document).ready(function () {
		if ($(".swiper").hasClass("swiper-hero")) {
			var swiperHero = new Swiper('.swiper-hero', {
				autoplay: true,
				direction: 'horizontal',
				effect: 'slide',
				lazy: true,
				loop: true,
				parallax: true,
				preloadImages: true,
				speed: 1250,
			});
		}

		$('.discount__close').on('click', function() {
			$('.discount__close').addClass('hide');
			$('.discount').addClass('hide')
		});
	});