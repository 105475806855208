$(document).ready(function () {
	if ($(".swiper").hasClass("swiper-reviews")) {
		const swiperReviewsTop = new Swiper(".swiper-reviews", {
			loop: true,
			speed: 800,
			grabCursor: true,
			autoHeight: true,
			autoplay: true,
			delay: 2000,
		});
	}
});