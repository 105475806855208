	$(document).ready(function () {
		if ($("form").is("formContact")) {
			document.getElementById('formContact').addEventListener('submit', function(event) {
				event.preventDefault();
		
				const formData = new FormData(this);
				const data = Object.fromEntries(formData.entries());
		
				fetch('https://api.pdrschool.net/api/send-message', {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json'
					},
					body: JSON.stringify(data)
				}).then(response => {
					if (response.ok) {
						alert('Message sent!');
					} else {
						alert('Error sending message');
					}
				}).catch(error => {
					console.error('Error:', error);
					alert('Error sending message');
				});
			});
		}
	});