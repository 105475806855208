	gsap.registerPlugin(ScrollTrigger);

	const lenis = new Lenis()

	lenis.on('scroll', ScrollTrigger.update)

	gsap.ticker.add((time)=>{
		lenis.raf(time * 1000)
	})

	gsap.ticker.lagSmoothing(0)

	let direction = 0;

	const scroll = ScrollTrigger.create({
		onUpdate: (self) => {
			if (self.direction !== direction) {
				direction = self.direction;
				//console.log(direction)
				gsap.to('header', { autoAlpha: direction === 1 ? 0 : 1 });
			}
		}
	});