	$(document).ready(function() {
		if ($('main').is('#tools')) {
			var containerEl = document.querySelector('.load-more');
			var mixer = mixitup(containerEl);

			$('.owl-carousel').owlCarousel({
				dots: false,
				margin: 8,
				nav: true,
				items: 1
			});

			$('.btn-uah').on('click', function () {
				$('.btn-uah').addClass('active');
				if ($('.btn-uah').hasClass('active')) {
					$('.btn-usd').removeClass('active');
					$('.price-uah').addClass('price-active');
					$('.price-usd').removeClass('price-active');
				} else {
					$('.price-uah').removeClass('price-active');
					$('.price-usd').addClass('price-active');
					$('.btn-usd').addClass('active');
				}
			});

			$('.btn-usd').on('click', function () {
				$('.btn-usd').addClass('active');
				if ($('.btn-usd').hasClass('active')) {
					$('.btn-uah').removeClass('active');
					$('.price-usd').addClass('price-active');
					$('.price-uah').removeClass('price-active');
				} else {
					$('.price-uah').addClass('price-active');
					$('.price-usd').removeClass('price-active');
					$('.btn-uah').addClass('active');
				}
			});
		}
	});