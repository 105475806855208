	$(document).ready(function() {
		$(".form-callback").magnificPopup({
			type: "inline",
			mainClass: "mfp-fade",
			preloader: false,
			showCloseBtn: true,
			closeOnBgClick: true,
			enableEscapeKey: true,
			fixedContentPos: false,
			modal: false
		});
	});

	$(document).ready(function () {
		if ($("form").is("formCallBack")) {
			document.getElementById('formCallBack').addEventListener('submit', function(event) {
				event.preventDefault();
			
				const formData = new FormData(this);
				const data = Object.fromEntries(formData.entries());
			
				fetch('https://api.pdrschool.net/api/send-callback', {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json'
					},
					body: JSON.stringify(data)
				}).then(response => {
					if (response.ok) {
						alert('Message sent!');
					} else {
						alert('Error sending message');
					}
				}).catch(error => {
					console.error('Error:', error);
					alert('Error sending message');
				});
			});
		}
	});