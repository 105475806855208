	/*========== Start Google Map ==========*/
	$(document).ready(function() {
		if ($('div').is('#map')) {
			let map;

			async function initMap() {
				const { Map } = await google.maps.importLibrary("maps");
				const { AdvancedMarkerElement, PinElement } = await google.maps.importLibrary("marker");

				map = new Map(document.getElementById("map"), {
					center: { lat: 50.3859813, lng: 30.4954246 },
					draggable: true,
					mapId: "DEMO_MAP_ID", // Map ID is required for advanced markers.
					scrollwheel: false,
					zoom: 11
				});

				const locations = [
					{lat: 50.396010094516335, lng: 30.48819306036403},
					{lat: 50.47859190370473, lng: 30.492070969322665},
					{lat: 50.27248375874532, lng: 30.33840336780104},
					{lat: 50.39600821277306, lng: 30.488185938171206}
				];

				var marker, i;
				for (i = 0; i < locations.length; i++) {
					marker = new google.maps.marker.AdvancedMarkerElement({
						position: new google.maps.LatLng(locations[i], locations[i]),
						map: map,
						title: "Ми знаходимося тут!"
					});
				}
			}

			initMap();
		}
	});

	/*========== End Google Map ==========*/